@tailwind base;
@tailwind components;
@tailwind utilities;

.info-panel-content p {
  @apply mt-4;
}

/*
override intercom z-index so it's hidden behind notifications
*/
.intercom-lightweight-app {
  z-index: 10000 !important;
}

.intercom-lightweight-app-launcher {
  z-index: 10000 !important;
}

/* This is a special class that will be replaced with "display: block !important" when exported to .pdf */
.pdf-export-only {
  display: none;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
