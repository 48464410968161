h1,
h2 {
  font-family: "EB Garamond", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 74px;
}

h3 {
  font-family: "EB Garamond", serif;
  font-weight: 700;
  font-style: normal;
  font-size: 45px;
}
